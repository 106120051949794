import { useLocation } from '@reach/router';
import { useIntl } from 'react-intl';

const usePathName = () => {
  const location = useLocation();
  const { locale } = useIntl();
  return location.pathname.replace(`/${locale}`, '').replace(/\//g, '');
};

export default usePathName;
