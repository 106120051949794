import styled from 'styled-components';

export const Svg = styled.svg`
  width: 120px;
  height: 37.65px;
  display: block;
`;

export const TextPath = styled.path`
  fill: ${(props) => props.$color};
`;
